import Loading from "components/Loading";
import {
  useGetHolisticsTokenLazyQuery,
  useLocationEmbeddedAnalyticsInfoQuery,
} from "pacts/app-webcore/hasura-webcore.graphql";
import React, { useEffect, useState } from "react";

type InsightsDasboardDetailProps = {
  embedId: string;
  locationId: string;
  isHolistics?: boolean;
};

function InsightsDasboardDetail({ embedId, locationId, isHolistics }: InsightsDasboardDetailProps) {
  const { data, loading } = useLocationEmbeddedAnalyticsInfoQuery({
    variables: {
      locationId,
      input: {
        embedId,
      },
    },
    skip: isHolistics,
  });
  const [holisticsPayload, setHolisticsPayload] = useState<string>("");

  const [getHolisticsPayload] = useGetHolisticsTokenLazyQuery({
    variables: { embedId, locationId },
    onCompleted: (holisticsData) => {
      setHolisticsPayload(holisticsData.getHolisticsToken ?? "");
    },
  });

  const iframeRef = React.useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (isHolistics) getHolisticsPayload();

    const handleMessage = (event: MessageEvent) => {
      try {
        if (event.data && event.data.params.height) {
          iframeRef.current?.setAttribute("height", `${event.data.params.height}px`);
        }
      } catch (e) {
        // handle this else page will be unusable
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [embedId, getHolisticsPayload, isHolistics]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      {isHolistics ? (
        <iframe
          title="holistics embed"
          src={`https://secure.holistics.io/embed/${embedId}?_token=${holisticsPayload}`}
          id="embedded-iframe"
          width="100%"
          height="600px"
          frameBorder="0"
          allowFullScreen
        />
      ) : (
        <iframe
          id="iframe"
          title="embed analytics"
          ref={iframeRef}
          data-hj-allow-iframe
          srcDoc={`
        <html>
          <body>
            <form id="form" action="${data?.locationEmbeddedAnalyticsInfo.url}" method="post">
              <input type="hidden" name="embedToken" value="${data?.locationEmbeddedAnalyticsInfo.token}">
            </form>
            <script>
              document.getElementById("form").submit();
              document.addEventListener("DOMContentLoaded", function () {
                var height = document.body.scrollHeight;
                parent.postMessage({ iframeHeight: height }, "*");
              });
            </script>
          </body>
        </html>
      `}
          width="100%"
          height="500px" // default height
          style={{ border: 0 }}
        />
      )}
    </div>
  );
}

export default InsightsDasboardDetail;
